import React, { Component } from "react";

class Welcome extends Component {
	render() {
		return (
			<>
				<div className="site-welcome" id="section1">
					<div className="container">
						<div className="row">
							<div className="col-md-5">
								<div className="margin-welcome hidden-sm-down"></div>
								<h2 className="welcome">Welcome</h2>
								<p className="desc-welcome">
									timeless and iconic ornaments, created as a way of seeing life
									in harmony with nature.
								</p>
							</div>
							<div className="col-md-7">
								<img src="/img/welkomme.png" width="100%" alt="" />
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default Welcome;
