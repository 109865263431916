import React, { Component } from "react";

class Nav extends Component {
	render() {
		return (
			<>
				<noscript>You need to enable JavaScript to run this app.</noscript>
				<nav
					className="navbar navbar-light bg-faded navbar-fixed-top"
					style={{ position: "fixed" }}
				>
					<div className="container">
						<a
							className="navbar-brand float-xs-left text-xs-center text-sm-left"
							href="/"
						>
							<img src="/img/main_logo.png" alt="" width="100%" />
						</a>
						<br />
						<button
							className="navbar-toggler hidden-lg-up float-xs-right"
							type="button"
							data-toggle="collapse"
							data-target="#navbarResponsive"
							aria-controls="navbarResponsive"
							aria-expanded="false"
							aria-label="Toggle navigation"
						></button>
						<div className="collapse navbar-toggleable-md" id="navbarResponsive">
							<ul className="nav navbar-nav float-lg-right text-xs-center margin-responsive">
								<li className="nav-item">
									<a className="nav-link" href="#section1">
										HOME
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#section2">
										PHILOSOPHY
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#section3">
										GALLERY
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#section4">
										PRESS
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#section5">
										CONTACT
									</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</>
		);
	}
}
export default Nav;
