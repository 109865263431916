import React, { Component } from "react";

import $ from "jquery";

import Slider from "react-slick";

class SliderDude extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.galleries();
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}

	galleries() {
		const urlpepek = "https://e-kollektion.com/pepek.php";
		// const urlpepek = '/pepek.php';
		$.ajax({
			url: urlpepek,
			success: (pepekgallery) => {
				// console.log(pepekgallery);

				let galleryRows = [];

				//looping array object
				pepekgallery.forEach((gallery) => {
					gallery.alt = gallery.no_urut + gallery.gallery_id;
					gallery.imageurl = "/img/gallery/" + gallery.images;
					// console.log(gallery.imageurl);

					// galleryRows.push(
					//     <div className="item" key={gallery.gallery_id} style={{maxHeight: "400px"}}>
					//         <a href={gallery.imageurl}><img style={{paddingLeft: '15px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: "url(" + gallery.imageurl + ")",}} height="400" width="370" src="img/ekollek.png" alt={gallery.alt} /></a>
					//     </div>
					// );

					// galleryRows.push(
					//     <div key={gallery.gallery_id}><a  href={gallery.imageurl}><img src={gallery.imageurl} alt={gallery.alt} /></a></div>
					// );

					galleryRows.push(
						<div key={gallery.gallery_id} style={{ maxHeight: "400px", width: 370 }}>
							<a href={gallery.imageurl}>
								<img
									style={{
										marginLeft: "30px",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
										backgroundImage: "url(" + gallery.imageurl + ")",
									}}
									height="400"
									src="img/ekollek.png"
									alt={gallery.alt}
								/>
							</a>
						</div>
					);
				});

				// this.setState({pekpekliu: galleryRows});
				this.setState({ galleryRows });
				// this.setState(galleryRows);
			},
		});
	}

	next() {
		this.slider.slickNext();
	}

	previous() {
		this.slider.slickPrev();
	}

	render() {
		const settings = {
			className: "center slider variable-width",
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 3,
			centerPadding: "60px",
			variableWidth: true,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: false,
						dots: false,
					},
				},
				{
					breakpoint: 979,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						initialSlide: 3,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					},
				},
			],
		};
		return (
			<div className="site-gallery" id="section3">
				<div className="container">
					<div className="row">
						<div className="col-xs-12">
							<h2 className="gallery">Gallery</h2>
						</div>
					</div>
					<div className="row">
						<div id="leak" className="col-xs-12">
							<Slider ref={(c) => (this.slider = c)} {...settings}>
								{this.state.galleryRows}
							</Slider>
							<div style={{ textAlign: "center", marginTop: "20px" }}>
								<button className="leakngakakbutton" onClick={this.previous}>
									Previous
								</button>
								<button className="leakngakakbutton" onClick={this.next}>
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SliderDude;
// from https://react-slick.neostack.com/docs/example/previous-next-methods
