import React, { Component } from "react";

class Press extends Component {
	render() {
		return (
			<div className="site-press" id="section4">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 hidden-md-up">
							<h2 className="press">Press</h2>
							<br />
						</div>
						<div className="col-md-6">
							<img src="/img/press_girls.jpg" width="100%" alt="" />
						</div>
						<div className="col-md-6">
							<h2 className="press hidden-sm-down">Press</h2>
							<br />
							<iframe
								title="pideo"
								src="https://player.vimeo.com/video/69685410?title=0&amp;byline=0&amp;portrait=0&amp;color=ffffff"
								width="100%"
								height="364"
								frameBorder="0"
								webkitallowfullscreen="true"
								mozallowfullscreen="true"
								allowFullScreen
							></iframe>
							<br />
							<br />
							<img src="/img/press_thumb.png" width="100%" alt="" />
						</div>
					</div>
					<br />
					<br />
					<div className="row">
						<div className="col-md-6">
							<img src="/img/dadong.jpg" width="100%" alt="" />
						</div>
						<div className="col-md-6">
							<p className="press">
								Vogue UK <br />
								Vogue Germany <br />
								BOLERO Swiss <br />
								KEE magazine Hong Kong <br />
								Harper's Bazaar <br />
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Press;
