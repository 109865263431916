import React, { Component } from "react";

class Footer extends Component {
	render() {
		const CurrentYear = new Date().getFullYear();
		return (
			<footer className="footer">
				<div className="container">
					<div className="row">
						<div className="col-xs-12">
							<p className="text-xs-center footer">
								© {CurrentYear} Ekollektion, all rights reserved Created By Visual
								Identity Production
							</p>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
export default Footer;
