import React, { Component } from "react";

class Philo extends Component {
	render() {
		return (
			<div className="site-philo" id="section2">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<img src="/img/egg.jpg" width="100%" alt="" />
						</div>
						<div className="col-md-6">
							<h2 className="philo">Philosophy</h2>
							<p className="philo text-justify">
								One day, it dawned on me that I would not invent anything new.
								Everything, every shape had already been created by nature. What I
								could do was turn that natural beauty into jewels; that's what I
								have been doing with great pleasure for more than 30 years...
								<br />
								<br />
								E'KOLLEKTION is designed to reveal human beauty in everyone young,
								old, male, female...
								<br />
								<br />
								My work being inspired by nature and everything that makes an
								impression on me - object, vegetal, mineral, animal, shape, sound,
								and people - I see it as universal.
								<br />
								<br />
								Handmade in Indonesia by wonderfully talented persons, the
								necklaces, bracelets, earrings and rings I design are unique.
								<br />
								<br />
								Silver is the material that I work with best, it is pure, simple,
								soft and strong...its light and energy are unmatched.
								<br />
								<br />
								My vision is to create timeless and iconic ornaments, making
								E'KOLLEKTION a way of seeing life in harmony with nature. Ernesto
								Sturzenegger
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12 text-xs-center">
							<div className="founder">
								<img src="/img/founder.jpg" alt="" width="100%" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Philo;
