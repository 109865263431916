import React, { Component } from "react";
import { loadReCaptcha } from "react-recaptcha-v3";

import Nav from "./_nav";
import Welcome from "./_welcome";
import Philo from "./_philo";
import SliderDude from "./_gallery";
import Press from "./_press";
import Contact from "./_contact";
import Footer from "./_footer";

const config = {
	// api: `${process.env.REACT_APP_API}`,
	// title: 'Contact Me',
	successMessage: "Thank You! Your message has been sent.",
	errorMessage: "Oops! Something went wrong and we could not send your message.",
	// fields:  [
	//     { id: 1, label: 'First Name', type: 'text',placeholder:'Your First Name', isRequired: true , klassName:'first-name-field'},
	//     { id: 2, label: 'Last Name', type: 'text', placeholder: 'Your Last Name', isRequired: true , klassName:'last-name-field'},
	//     { id: 3, label: 'Email', type: 'email', placeholder: ' Your Email', isRequired: true , klassName:'email-field'},
	//     { id: 4, label: 'Message', type: 'textarea',placeholder:'Write something.....', isRequired: true , klassName:'message-field'}
	// ]
};

class App extends Component {
	componentDidMount() {
		loadReCaptcha("6LeRTKQUAAAAADN3qbN63X_98ao185ukmVlYa2CU");
	}

	render() {
		return (
			<>
				<Nav />
				<Welcome />
				<Philo />
				<SliderDude />
				<Press />
				<Contact config={config} />
				<Footer />
			</>
		);
	}
}

export default App;
