import React, { Component } from "react";
import { ReCaptcha } from "react-recaptcha-v3";

import PropTypes from "prop-types";
import axios from "axios";

class Contact extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputName: "",
			inputEmail: "",
			inputSubject: "",
			inputMessage: "",
			verified: false,
			mailSent: false,
			error: false,
		};

		// this.handleInput = this.handleInput.bind(this);
		// this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	verifyCallback = (recaptchaToken) => {
		// Here you will get the final recaptchaToken!!!
		this.setState({
			verified: true,
		});
		// console.log(recaptchaToken, "<= your recaptcha token")
	};

	handleFormSubmit = (event) => {
		event.preventDefault();
		axios({
			method: "post",
			url: `./mailer.php`,
			// url: `http://localhost/ekole/public/mailer.php`,
			headers: { "content-type": "application/json" },
			data: this.state,
		})
			.then((result) => {
				this.setState({
					mailSent: result.data.sent,
				});
				console.log(this.state);
			})
			.catch((error) =>
				this.setState({
					error: error.message,
				})
			);

		this.setState({
			inputName: "",
			inputEmail: "",
			inputSubject: "",
			inputMessage: "",
			verified: false,
			mailSent: true,
			error: false,
		});
	};

	render() {
		const { successMessage, errorMessage } = this.props.config;
		return (
			<>
				<div className="site-contact" id="section5">
					<div className="container">
						<div className="row">
							<div className="col-lg-8 col-md-12"></div>
							<div className="col-lg-4 col-md-12">
								<h2 className="contact">CONTACT</h2>
								<br />
								<table>
									<tbody>
										<tr>
											<th colSpan="3">E'Kollektion Switzerland</th>
										</tr>
										<tr>
											<td style={{ width: "30%" }}>phone</td>
											<td>:</td>
											<td>0041 (043) 366 56 46</td>
										</tr>
										<tr>
											<td>fax</td>
											<td>:</td>
											<td>0041 (043) 366 56 46</td>
										</tr>
									</tbody>
								</table>
								<br />
								<div className="contact">
									<form>
										<p className="clearfix ass_m">
											<input
												onChange={(event) =>
													this.setState({ inputName: event.target.value })
												}
												type="text"
												className="right form-control"
												placeholder="Please enter your name"
												name="inputName"
												id="name"
												maxLength="255"
												required
											/>
											{/*value={this.state.inputName}*/}
										</p>
										<p className="clearfix ass_m">
											<input
												onChange={(event) =>
													this.setState({
														inputEmail: event.target.value,
													})
												}
												type="email"
												className="right form-control"
												placeholder="Enter your e-mail address"
												name="inputEmail"
												id="email"
												maxLength="50"
												required
											/>
										</p>
										<p className="clearfix ass_m">
											<input
												onChange={(event) =>
													this.setState({
														inputSubject: event.target.value,
													})
												}
												type="text"
												className="right form-control"
												placeholder="Please enter subject"
												name="inputSubject"
												id="subject"
												maxLength="255"
												required
											/>
										</p>
										<p className="clearfix ass_m">
											<textarea
												onChange={(event) =>
													this.setState({
														inputMessage: event.target.value,
													})
												}
												className="form-control"
												rows="4"
												placeholder="Please enter your message"
												name="inputMessage"
												id="query"
												required
											></textarea>
										</p>

										<ReCaptcha
											sitekey="6LeRTKQUAAAAADN3qbN63X_98ao185ukmVlYa2CU"
											action="homepage"
											verifyCallback={this.verifyCallback}
										/>

										<input
											onClick={(event) => this.handleFormSubmit(event)}
											type="submit"
											className="btn btn-default"
											value="Send"
											name="submit"
										/>
										<fieldset>
											{this.state.mailSent === true && (
												<p>{successMessage}</p>
											)}
											{this.state.error && (
												<p style={{ color: "red" }}>{errorMessage}</p>
											)}
										</fieldset>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Contact;
//propTypes for the component
Contact.propTypes = {
	config: PropTypes.object.isRequired,
};
